import React, { useEffect, useRef } from "react";
import ReceivedMessage from "./ReceivedMessage";

export default function ReceiveMessages({ id, data }) {
  const scrollRef = useRef(null); // Ref for scrolling to bottom

  const sent_message = data.filter((d) => d?.message?.from?.id === id);

  useEffect(() => {
    // Scroll to the bottom whenever sent_message changes
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: scrollRef.current.scrollHeight,
        behavior: "smooth", // Smooth scrolling effect
      });
    }
  }, [sent_message]); // Trigger scrolling when messages update

  return (
    <section
      ref={scrollRef} // Attach ref to the scrollable container
      className="w-full h-[calc(50vh)] rounded bg-slate-300 mb-2 p-5 overflow-scroll"
    >
      {sent_message.map((message, index) => (
        <ReceivedMessage message={message} key={index} />
      ))}
    </section>
  );
}
