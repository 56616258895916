import React from "react";
import fiqh_1_channel from "../../../../assets/fiqh/group_logo/fiqh_1_channel.png";
import fiqh_1_female from "../../../../assets/fiqh/group_logo/fiqh_1_female.png";
import fiqh_1_male from "../../../../assets/fiqh/group_logo/fiqh_1_male.png";

import fiqh_2_channel from "../../../../assets/fiqh/group_logo/fiqh_2_channel.png";
import fiqh_2_female from "../../../../assets/fiqh/group_logo/fiqh_2_female.png";
import fiqh_2_male from "../../../../assets/fiqh/group_logo/fiqh_2_male.png";

import { faHand } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function FiqhGroupCard({ batch, gender, level }) {
  const links = {
    8: {
      male_1: "https://t.me/+TY0W0XVtGuE0ZWZl",
      female_1: "https://t.me/+TY0W0XVtGuE0ZWZl",
      channel_1: "https://t.me/+TY0W0XVtGuE0ZWZl",

      male_2: "https://t.me/+BV4JY7QjXwU1YjQ0",
      female_2: "https://t.me/+gzKtjxET40U5Yzhk",
      channel_2: "https://t.me/+UAT4TCwhqQA0ZjVk",
    },
    9: {
      male_1: "https://t.me/+0fm9kJO_BsplZjFk",
      female_1: "https://t.me/+BrNuD5XKz8YxMzI8",
      channel_1: "https://t.me/+JSGuSuN2uF84NmU0",
    },
  };

  const getLink = (type) => {
    if (links[batch] && links[batch][type]) {
      return links[batch][type];
    } else {
      return "";
    }
  };
  const imageMap = {
    1: {
      male: fiqh_1_male,
      female: fiqh_1_female,
      channel: fiqh_1_channel,
    },
    2: {
      male: fiqh_2_male,
      female: fiqh_2_female,
      channel: fiqh_2_channel,
    },
  };

  if (links[batch])
    return (
      <div className="p-5 rounded bg-gradient-to-tl from-sky-300">
        <p>
          <span className="text-xl">Join </span>
          <FontAwesomeIcon className="text-blue-600" icon={faHand} fade />
        </p>

        <div className="mt-4 flex justify-around">
          <a
            href={getLink(`${gender}_${level}`)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div>
              <div>
                <img className="w-20" src={imageMap[level][gender]} alt="" />
              </div>
              <p className="text-center">Class Group</p>
            </div>
          </a>

          <a
            href={getLink(`channel_${level}`)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div>
              <div>
                <img className="w-20" src={imageMap[level]["channel"]} alt="" />
              </div>
              <p className="text-center">Lecture Notes</p>
            </div>
          </a>
        </div>
      </div>
    );
}
