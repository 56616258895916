import React, { useState } from "react";

export default function ReceivedMessage({ message }) {
  const [imgUrl, setImgUrl] = useState("");

  const image_id = message?.message?.photo?.[0]?.file_id;

  const getImage = async (quality = 0) => {
    const image_id_q = message?.message?.photo?.[quality]?.file_id;

    await fetch(
      `https://api.telegram.org/${process.env.REACT_APP_TELEGRAM_API}/getFile?file_id=${image_id_q}`
    )
      .then((response) => response.json())
      .then((fileInfo) => {
        if (fileInfo.ok) {
          const file_path = fileInfo.result.file_path;
          setImgUrl(
            `https://api.telegram.org/file/${process.env.REACT_APP_TELEGRAM_API}/${file_path}`
          );
        } else {
          console.error("Failed to get image file path:", fileInfo.description);
        }
      });
  };

  const text = message?.message?.text;
  const sticker = message?.message?.sticker?.emoji;
  const forwarded = message?.message?.forward_from;
  const group = message?.message?.chat?.title;

  const timestamp = message?.message?.date; // 1731451473

  const date = new Date(timestamp * 1000); // Multiply by 1000 to convert seconds to milliseconds
  const formattedDate = date.toLocaleString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  return (
    <div className="bg-blue-300 my-2 p-1 rounded px-5">
      <div className="italic flex justify-between">
        <a
          target="_blank"
          href={`https://web.telegram.org/a/#${message?.message?.from?.id}`}
          className="badge"
          rel="noreferrer"
        >
          {message?.message?.from?.first_name}
        </a>
        <p className="text-slate-500">{formattedDate}</p>
      </div>

      <div className="px-5">
        {forwarded && (
          <a
            target="_black"
            href={`https://web.telegram.org/a/#${forwarded.id}`}
            className=" badge badge-accent"
          >
            {forwarded.first_name}
          </a>
        )}
        <p>
          {text &&
            text.split("\n").map((item, index) => (
              <span key={index}>
                {item}
                <br />
              </span>
            ))}
        </p>
        <p>{sticker}</p>
      </div>
      {message?.message?.photo && (
        <div>
          {message?.message?.photo?.map((i, index) => (
            <p
              className="cursor-pointer badge badge-outline m-1"
              onClick={() => getImage(index)}
              key={i.file_unique_id}
            >
              Img : {index + 1}
            </p>
          ))}
          <img className="w-1/3 rounded" src={imgUrl} alt="" />
        </div>
      )}
    </div>
  );
}
