import React from "react";
import tele_bot from "../../../../assets/icons/tele_bot.png";

export default function TelegramBotCard({ info }) {
  return (
    <section>
      <p className="text-xl text-blue-600 mt-24">মেসেজ দিন</p>
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="order-3 md:order-1 ">
          <p>
            ফর্ম পূরন করার সময় অনেকেই ভুল নাম্বার দেন। এইজন্য আমরা পরে আর
            যোগাযোগ করতে পারিনা।
          </p>
          <p>একটা মেসেজ দিবেন কি, যেন আমরা আপনাকে হারিয়ে না ফেলি?</p>
          <p className="text-blue-600">
            মেসেজে লিখুন - আপনার নাম, কোর্সের নাম,{" "}
            {info.payment === "paid"
              ? "কোর্স ফী পাঠিয়েছি"
              : info.payment === "due"
              ? "কিছুদিনের মধ্যে কোর্স ফী পাঠাব"
              : info.payment === "scholarship"
              ? "স্কলারশিপের জন্য আবেদন করেছি"
              : "সেন্ডমানি করেছেন কিনা"}
          </p>
          <p className="text-center my-5">
            <a
              className="px-3 py-1 rounded text-white  bg-green-600 animate-pulse"
              target="_blank"
              href="https://t.me/mini_admin_1_bot"
              rel="noreferrer"
            >
              Hello Mini 👋
            </a>
          </p>
        </div>
        <div className="order-2">
          <a
            target="_blank"
            href="https://t.me/mini_admin_1_bot"
            rel="noreferrer"
          >
            <img
              className="mx-auto w-20 md:w-32 my-5 md:my-0"
              src={tele_bot}
              alt=""
            />
          </a>
        </div>
      </div>
    </section>
  );
}
