import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    // List of pages to track
    const trackedPages = [
      "/",
      "/aqeedah",
      "/arabic",
      "/fiqh",
      "/seerat",
      "/tafseer",
      "/dua",
      "/registration",
      "/registration-fiqh",
      "/registration-seerat",
      "/registration-arabic",
      "/registration-aqeedah",
      "/registration-ramadan-siyam",
      "/registration-tajweed",
      "/registration-dua",
      "/registration-tafseer",
      "/courses",
      "/courses/aqeedah",
      "/courses/fiqh",
      "/courses/seerat",
      "/courses/tajweed",
      "/courses/arabic",
      "/courses/dua",
      "/courses/ramadan-siyam",
      "/courses/tafseer",
      "/courses/asmaul-husna",
    ];

    const currentPath = location.pathname;

    if (trackedPages.includes(currentPath)) {
      // Update GTM Data Layer
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "pageview",
        pagePath: currentPath,
      });

      // Meta Pixel Page View Tracking
      if (window.fbq) {
        window.fbq("track", "PageView");
      }
    }
  }, [location]);
};

export default usePageTracking;
