import axios from "axios";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import digitConverter from "../../../utils/digitConverter";
import thConverter from "../../../utils/thConverter";
import LoadingTrophy from "./../../../Loading/LoadingTrophy";
import FiqhLBRow from "./FiqhLBRow.js";

const FiqhLeaderboard = () => {
  const [myPosition, setMyPosition] = useState(0);

  const params = useParams();
  const level = params.level;
  const batch = params.batch;

  const getStudentList = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_API}/fiqh/leaderboard/get/${batch}/${level}`
    );

    return data;
  };

  const sn = params.sn;
  const queryName = "fiqh_leaderBoard" + level;

  const {
    isLoading,
    isFetching,
    data: students,
    refetch,
  } = useQuery(queryName, getStudentList);

  if (isLoading) {
    return <LoadingTrophy />;
  }

  const myResult = (students?.result).find(
    (student) => student.sn == params.sn
  );

  // dynamic merit position
  let p = 500;
  let i = -1;
  const getPosition = (score) => {
    if (!score) return;

    if (score < p) {
      p = score;
      i = i + 1;
    }
    return i;
  };

  return (
    <div>
      <div className="text-center my-10 text-xl">
        <p>Welcome to Leaderboard </p>
        <p>
          Course: Fiqh Level {level} (Batch: {params.batch})
        </p>
      </div>

      <div>
        <div className="overflow-x-auto w-10/12 mx-auto mb-20">
          <table className="table w-full">
            <thead>
              <tr>
                <th className="bg-green-200">Merit</th>
                <th>SN</th>
                <th>Name</th>
                <th>Exm_1</th>
                <th>Exm_2</th>
                <th>Exm_3</th>
                <th className="bg-red-200">Total</th>
              </tr>
            </thead>
            <tbody>
              <tr className="">
                <th className="bg-yellow-100 font-bold">
                  {digitConverter(myPosition)}{" "}
                  <sup>{thConverter(myPosition)}</sup>
                </th>
                <th className="bg-yellow-100">{myResult?.sn}</th>
                <td className="bg-yellow-100">
                  {myResult?.name.toUpperCase()}
                </td>
                {level === "2" && (
                  <>
                    <th className="bg-yellow-100">
                      {myResult?.fiqh2data[0].Score}
                    </th>
                    <th className="bg-yellow-100">
                      {myResult?.fiqh2data[1].Score}
                    </th>
                    <th className="bg-yellow-100">
                      {myResult?.fiqh2data[2].Score}
                    </th>
                    <th className="bg-yellow-100 text-red-600">
                      {myResult?.fiqh2Total}
                    </th>
                  </>
                )}
                {level === "1" && (
                  <>
                    <th className="bg-yellow-100">
                      {myResult?.fiqh1data[0].Score}
                    </th>
                    <th className="bg-yellow-100">
                      {myResult?.fiqh1data[1].Score}
                    </th>
                    <th className="bg-yellow-100">
                      {myResult?.fiqh1data[2].Score}
                    </th>
                    <th className="bg-yellow-100 text-red-600">
                      {myResult?.fiqh1Total}
                    </th>
                  </>
                )}
              </tr>
              <tr>
                <td></td>
              </tr>

              {students?.result?.map((student, index) => (
                <FiqhLBRow
                  key={index}
                  merit={getPosition(student[`fiqh${level}Total`])}
                  student={student}
                  setMyPosition={setMyPosition}
                  level={level}
                  mySn={myResult?.sn}
                ></FiqhLBRow>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default FiqhLeaderboard;
