import { faFilePdf } from "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useLocation, useParams } from "react-router-dom";
import auth from "../../../../firebase.init";
import useAdmin from "../../../hooks/useAdmin";

import aq_certi_2 from "../../../../assets/certi_img/aq_certi_1.gif";
import aq_certi_1 from "../../../../assets/certi_img/aq_certi_2.gif";
import femaleProfile from "../../../../assets/profile_avatar/female-profile.png";
import maleProfile from "../../../../assets/profile_avatar/male-profile.png";

import digitConverter from "../../../utils/digitConverter";
import thConverter from "../../../utils/thConverter";
import useModerator from "./../../../hooks/useModerator";
import Profile_1_Skeleton from "./../../../Loading/Profile_1_Skeleton";
import SeeratLevelComponents from "./SeeratLevelComponents";
import SeeratPaymentCard from "./SeeratPaymentCard";

const SeeratDetails = () => {
  const params = useParams();
  const nev = useLocation();

  const [student, setStudent] = useState(nev.state?.student);
  const [user, loading, error] = useAuthState(auth);
  const [admin] = useAdmin(user);
  const [moderator] = useModerator(user);
  const [adminDesk, setAdminDesk] = useState(false);

  const batchNo = parseInt(params.batch);

  const getStudent = () => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_API}/seerat/${batchNo}/sn/${params.sn}`
      )
      .then((data) => {
        setStudent(data.data);
      });
  };

  if (!student) {
    getStudent();
    return (
      <div className="my-10">
        <p className="text-center text-2xl">
          সীরাতুন নবী (সা) কোর্সের {digitConverter(batchNo)}
          {thConverter(batchNo)} ব্যাচে স্বাগতম!
        </p>
        <Profile_1_Skeleton />
      </div>
    );
  }

  const payment1Update = () => {
    const data = {
      seerat1payment: "paid",
    };

    axios
      .put(
        `${process.env.REACT_APP_BACKEND_API}/seerat/${batchNo}/payment/${params.sn}`,
        data
      )
      .then((data) => {
        getStudent();
      });
  };

  return (
    <div className="container mx-auto mb-40 px-5">
      <p className="text-center text-2xl my-10">
        সীরাত কোর্সের {digitConverter(params.batch)}
        {thConverter(params.batch)} ব্যাচে স্বাগতম!
      </p>
      <div className="rounded bg-gradient-to-tl from-sky-200 flex flex-col md:flex-row justify-center md:justify-between">
        <div className="flex items-center p-5">
          <div className="mr-5">
            <img
              src={student.gender === "female" ? femaleProfile : maleProfile}
              alt=""
            />
          </div>
          <div>
            <p className="">
              SN <strong>{params.sn}</strong>
            </p>
            <p className="md:text-3xl">
              <strong>{student.name.toUpperCase()}</strong>
            </p>
            {student.fatherName && (
              <p className="md:text-xl">
                {student.gender === "female" ? "BINT" : "BIN"}{" "}
                <strong>{student.fatherName.toUpperCase()}</strong>
              </p>
            )}
            <p className="">
              <FontAwesomeIcon icon="fa-solid fa-phone" />{" "}
              <strong>{student.phone}</strong>
            </p>
          </div>
        </div>

        <div className="flex flex-col md:flex-row p-5 gap-10">
          {student.seerat1payment && (
            <SeeratPaymentCard
              payment={student.seerat1payment}
              level={1}
            ></SeeratPaymentCard>
          )}
          {student.seerat2payment && (
            <SeeratPaymentCard
              payment={student.seerat2payment}
              level={2}
            ></SeeratPaymentCard>
          )}
        </div>
      </div>

      {/* seerat Certificate  */}
      {(student.seeratCertificate || student.seeratCourseCertificates) && (
        <div>
          <p className="text-2xl mt-12 mb-5">
            অভিনন্দন! আপনি সার্টিফিকেট পেয়েছেন!
          </p>

          <div className="grid grid-cols-1 md:grid-cols-4 gap-10">
            {student.seeratCourseCertificates?.level1 && (
              <div className={`text-center p-5 rounded bg-green-100 w-fit `}>
                <img className="rounded" src={aq_certi_1} alt="" />
                {student.seerat1payment == "due" ? (
                  <Link
                    className="bg-red-500 block mt-2 text-white p-2 rounded shadow"
                    to="/seerat-due"
                  >
                    বকেয়া পরিশোধ করুন
                  </Link>
                ) : (
                  <a
                    className="bg-green-500 block mt-2 text-white p-2 rounded shadow"
                    href={student.seeratCourseCertificates?.level1}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon className="pr-5" icon={faFilePdf} /> Level
                    1 Certificate
                  </a>
                )}
              </div>
            )}

            {student.seeratCourseCertificates?.level2 && (
              <div className="text-center p-5 rounded bg-sky-100 w-fit">
                <img className="rounded" src={aq_certi_2} alt="" />
                {student.seerat2payment == "due" ? (
                  <Link
                    className="bg-red-500 block mt-2 text-white p-2 rounded shadow"
                    to="/seerat-due"
                  >
                    বকেয়া পরিশোধ করুন
                  </Link>
                ) : (
                  <a
                    className="bg-blue-500 block mt-2 text-white p-2 rounded shadow"
                    href={student.seeratCourseCertificates?.level2}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon className="pr-5" icon={faFilePdf} /> Level
                    2 Certificate
                  </a>
                )}
              </div>
            )}
          </div>
        </div>
      )}

      {/* Level 1  */}
      {student.seerat1data && (
        <SeeratLevelComponents
          batch={params.batch}
          sn={student.sn}
          gender={student.gender}
          data={student.seerat1data}
          level={1}
        ></SeeratLevelComponents>
      )}

      {admin && (
        <div className="text-center mt-40">
          <div className="my-5  mx-auto">
            <div className="p-10 bg-slate-200 rounded-sm">
              <div className="flex items-center justify-center">
                <p className="text-xl font-medium text-purple-600 mr-10">
                  Admin Support
                </p>
                <input
                  type="checkbox"
                  className="toggle toggle-primary"
                  onChange={(e) => setAdminDesk(e.target.checked)}
                />
              </div>

              {adminDesk && (
                <div className="my-10">
                  <button
                    onClick={payment1Update}
                    className="btn p-2 px-4 m-2 btn-success rounded-md"
                  >
                    {" "}
                    Payement : Level 1
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SeeratDetails;
