import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { formatDateTime } from "../../../utils/submissonDateTime";
import Loading from "./../../../Loading/Loading";

const initialFormContent = {
  submissionDateTime: formatDateTime(),
  name: "",
  fathersName: "",
  phone: "",
  address: "",
  occupation: "",
  gender: "",
  level: "",
  payment: "",
  amount: "",
  senderNo: "",
  message: "",
};

export default function FiqhRegistrationForm({ setInfo }) {
  const [formData, setFormData] = useState(initialFormContent);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    setInfo({
      gender: formData.gender,
      level: formData.level,
      payment: formData.payment,
    });
  }, [formData.gender, formData.level, formData.payment]);

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (!formData.name) return toast.error("আপনার নাম লেখেননি");
    if (!formData.fathersName) return toast.error("আপনার বাবার নাম লেখেননি");
    if (!formData.phone) return toast.error("টেলিগ্রাম/ফোন নাম্বার লেখেননি");
    if (!formData.gender) return toast.error("গ্রুপ সিলেক্ট করেননি");
    if (!formData.level) return toast.error("লেভেল সিলেক্ট করেননি");
    if (!formData.payment) return toast.error("সেন্ডমানি করেছেন কি?");

    setIsLoading(true);

    const formDataObject = new FormData();
    Object.keys(formData).forEach((key) => {
      formDataObject.append(key, formData[key]);
    });

    fetch(
      "https://script.google.com/macros/s/AKfycbwJlgZ6IoSqzuiiY1GpAgAbZAfSwCdNkwFYVH2GZI0mthr41EAZE-AShztwYIkDpkYFqQ/exec",
      {
        method: "POST",
        body: formDataObject,
      }
    )
      .then((res) => res.text())
      .then(() => {
        setFormData(initialFormContent);
        setIsLoading(false);
        setFormSubmitted(true);
        toast.success("রেজিস্ট্রেশন সফল হয়েছে!");
      })
      .catch((error) => console.error(error));
  };

  if (formSubmitted) {
    return (
      <div className="container mx-auto my-10 text-center px-5">
        <p className="text-xl text-blue-600 my-5">
          ধন্যবাদ! আপনার তথ্য জমা হয়েছে, আমরা আপনার সাথে টেলিগ্রামে যোগাযোগ করব,
          ইনশাআল্লাহ!
        </p>
        <p className="text-9xl text-green-500">
          <FontAwesomeIcon icon={faCheckCircle} size="1x" />
        </p>
        {/* <button
          onClick={() => setFormSubmitted(false)}
          className="btn btn-sm btn-success px-3 my-3"
        >
          আরেকটি আবেদন করুন
        </button> */}
      </div>
    );
  }

  return isLoading ? (
    <Loading />
  ) : (
    <section>
      <div className="container mx-auto my-10 px-5">
        <section id="reg-form">
          <p className="text-xl text-blue-600 mt-10 mb-5">
            ভর্তি ফর্ম পূরন করুন
          </p>
          <div className="card flex-shrink-0 w-full shadow-2xl bg-base-100">
            <div className="card-body">
              <form id="reg-form" className="form" onSubmit={handleFormSubmit}>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                  <div>
                    <div className="form-control">
                      <label className="label">
                        <span className="label-text ">আপনার নাম</span>
                      </label>
                      <input
                        type="text"
                        placeholder="আপনার নাম লিখুন"
                        className="input input-bordered w-full max-w-xs"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="form-control">
                      <label className="label">
                        <span className="label-text">আপনার বাবার নাম</span>
                      </label>
                      <input
                        type="text"
                        placeholder="আপনার বাবার নাম লিখুন"
                        className="input input-bordered w-full max-w-xs"
                        name="fathersName"
                        value={formData.fathersName}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="form-control">
                      <label className="label">
                        <span className="label-text">
                          আপনার টেলিগ্রাম নাম্বার লিখুন
                        </span>
                      </label>
                      <input
                        type="text"
                        placeholder="+88017 XXX XXX XXX"
                        className="input input-bordered w-full max-w-xs"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-control">
                      <label className="label">
                        <span className="label-text">
                          আপনি কোন দেশে থাকেন?{" "}
                          <span className="text-orange-500">
                            (বাধ্যতামূলক নয়)
                          </span>
                        </span>
                      </label>
                      <input
                        type="text"
                        placeholder="জার্মানি"
                        className="input input-bordered w-full max-w-xs"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-control">
                      <label className="label">
                        <span className="label-text">
                          আপনার পেশা কি?{" "}
                          <span className="text-orange-500">
                            (বাধ্যতামূলক নয়)
                          </span>
                        </span>
                      </label>
                      <input
                        type="text"
                        placeholder="শিক্ষকতা"
                        className="input input-bordered w-full max-w-xs"
                        name="occupation"
                        value={formData.occupation}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div>
                    <div className="form-control">
                      <label className="label">
                        <span className="label-text">
                          কোন গ্রুপে জয়েন করবেন?
                        </span>
                      </label>
                      <label
                        htmlFor="field-male"
                        className="flex align-center mb-1"
                      >
                        <input
                          type="radio"
                          name="gender"
                          value="male"
                          id="field-male"
                          className="radio mr-3 checked:bg-sky-500"
                          onChange={handleChange}
                        />
                        <span>পুরুষ</span>
                      </label>
                      <label
                        htmlFor="field-female"
                        className="flex align-center mb-1"
                      >
                        <input
                          type="radio"
                          name="gender"
                          value="female"
                          id="field-female"
                          className="radio mr-3 checked:bg-pink-500"
                          onChange={handleChange}
                        />
                        <span>মহিলা</span>
                      </label>
                    </div>

                    <div className="form-control">
                      <label className="label">
                        <span className="label-text">
                          কোন লেভেলে জয়েন করবেন?
                        </span>
                      </label>
                      <label
                        htmlFor="level1"
                        className="flex align-center mb-1"
                      >
                        <input
                          type="radio"
                          name="level"
                          value="1"
                          id="level1"
                          className="radio mr-3 checked:bg-violet-500"
                          onChange={handleChange}
                        />
                        <span>লেভেল ১</span>
                      </label>
                      <label
                        htmlFor="level2"
                        className="flex align-center mb-1"
                      >
                        <input
                          type="radio"
                          name="level"
                          value="2"
                          id="level2"
                          className="radio mr-3 checked:bg-green-500"
                          onChange={handleChange}
                        />
                        <span>লেভেল ২</span>
                      </label>
                    </div>

                    <div className="form-control mt-5">
                      <label className="label">
                        <span className="label-text">
                          আপনি কি টাকা পাঠিয়েছেন?
                        </span>
                      </label>
                      <label
                        htmlFor="field-paid"
                        className="flex align-center mb-1"
                      >
                        <input
                          type="radio"
                          name="payment"
                          value="paid"
                          id="field-paid"
                          className="radio mr-3 checked:bg-green-500"
                          onChange={handleChange}
                        />
                        <span>টাকা পাঠিয়েছি</span>
                      </label>
                      <label
                        htmlFor="field-due"
                        className="flex align-center mb-1"
                      >
                        <input
                          type="radio"
                          name="payment"
                          value="due"
                          id="field-due"
                          className="radio mr-3 checked:bg-orange-500"
                          onChange={handleChange}
                        />
                        <span>টাকা পাঠাইনি</span>
                      </label>
                      {/* <label
                        htmlFor="field-scholarship"
                        className="flex align-center mb-1"
                      >
                        <input
                          type="radio"
                          name="payment"
                          value="scholarship"
                          id="field-scholarship"
                          className="radio mr-3 checked:bg-cyan-500"
                          onChange={handleChange}
                        />
                        <span>স্কলারশিপ পেতে চাই</span>
                      </label> */}
                    </div>

                    {formData.payment === "paid" && (
                      <>
                        <div className="form-control">
                          <label className="label">
                            <span className="label-text">
                              কতো টাকা পাঠিয়েছেন?
                            </span>
                          </label>
                          <input
                            type="text"
                            placeholder="এমাউন্ট লিখুন"
                            className="input input-bordered w-full max-w-xs"
                            name="amount"
                            value={formData.amount}
                            onChange={handleChange}
                          />
                        </div>

                        <div className="form-control">
                          <label className="label">
                            <span className="label-text">
                              কোন নাম্বার থেকে টাকা পাঠিয়েছেন? শেষ 4 ডিজিট লিখুন
                            </span>
                          </label>
                          <input
                            type="text"
                            placeholder="আপনি যে নাম্বার থেকে টাকা পাঠিয়েছেন"
                            className="input input-bordered w-full max-w-xs"
                            name="senderNo"
                            value={formData.senderNo}
                            onChange={handleChange}
                          />
                        </div>
                      </>
                    )}
                    {formData.payment === "scholarship" && (
                      <>
                        <div className="form-control">
                          <label className="label">
                            <span className="label-text">
                              <p>
                                ⚠ মানুষের দান-সদকার টাকা দিয়ে গরীব-অসহায়
                                ভাই-বোনদের স্কলারশিপের ব্যবস্থা করা হয়
                                (বিনামূল্যে পড়ার সুযোগ দেওয়া হয়)। আপনি কি মনে
                                করেন যে আপনি দান-সদকার টাকা নেওয়ার যোগ্য?
                              </p>
                              <p className="mt-2">
                                কেন আপনাকে স্কলারশিপ দেওয়া উচিত?
                              </p>
                            </span>
                          </label>
                          <textarea
                            type="text"
                            placeholder="একটি মেসেজ লিখুন, ব্যাখ্যা করুন কেন আপনাকে স্কলারশিপ দেওয়া প্রয়োজন। এই মেসেজটি আমাদের সম্মানিত কমিটির কাছে পাঠানো হবে।"
                            className="textarea textarea-bordered h-32 w-full max-w-xs"
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                          />
                        </div>
                      </>
                    )}

                    <div className="form-control mt-10">
                      <button
                        type="submit"
                        className="btn btn-primary w-full max-w-xs"
                      >
                        জমা দিন
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </section>
  );
}
