import React, { useEffect, useState } from "react";
import Loading from "../../../Loading/Loading";
import { formatDateTime } from "../../../utils/submissonDateTime";

import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";

const s_DateTime = formatDateTime();

const initialFormContent = {
  submissionDateTime: s_DateTime,
  name: "",
  fathersName: "",
  phone: "",
  address: "",
  occupation: "",
  gender: "",
  level: "",
  payment: "",
  amount: "",
  senderNo: "",
  message: "",
};

export default function SeeratRegistrationForm({ setInfo }) {
  const [formData, setFormData] = useState(initialFormContent);

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const app_script_api =
    "https://script.google.com/macros/s/AKfycbzUS9WYBSeStA2tGQNm5c-kwZtPsU8dufVEWL5rDepHq3lfUrEyCvi0h9nPqAlt8Ywe/exec";

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  useEffect(() => {
    setInfo({
      gender: formData.gender,
      payment: formData.payment,
    });
  }, [formData.gender, formData.payment]);

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (formData.name === "") {
      toast.error("আপনার নাম লেখেননি");
      return;
    }
    if (formData.fathersName === "") {
      toast.error("আপনার বাবার নাম লেখেননি");
      return;
    }
    if (formData.phone === "") {
      toast.error("টেলিগ্রাম/ফোন নাম্বার লেখেননি");
      return;
    }
    if (formData.gender === "") {
      toast.error("গ্রুপ সিলেক্ট করেননি");
      return;
    }

    if (formData.payment === "") {
      toast.error("সেন্ডমানি করেছেন কি?");
      return;
    }

    setIsLoading(true);

    const formDatab = new FormData();
    Object.keys(formData).forEach((key) => {
      formDatab.append(key, formData[key]);
    });

    fetch(app_script_api, {
      method: "POST",
      body: formDatab,
    })
      .then((res) => res.text())
      .then((data) => {
        console.log(data);
        setFormData(initialFormContent); //resetting the form
        setIsLoading(false);
        setFormSubmitted(true);
        toast.success("বুকিং সফল হয়েছে!");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (formSubmitted) {
    return (
      <div className="container mx-auto my-10 text-center px-5">
        <p className="text-xl text-blue-600 my-5">
          ধন্যবাদ! সীরাতুন নবী (সা) কোর্সের ৫ম ব্যাচে আপনার বুকিং সম্পন্ন হয়েছে!
        </p>
        <p className="text-9xl text-green-500">
          <FontAwesomeIcon
            icon={faCheckCircle}
            className="text-green-500"
            size="1x"
            fade
          />
        </p>
        {/* <p className="my-10">
          অন্য কারো জন্য বুকিং করতে চান?
          <br />
          <button
            onClick={() => setFormSubmitted(false)}
            className="btn btn-sm btn-success px-3 my-3 text-green-900"
          >
            আরেকটি আবেদন করুন
          </button>
        </p> */}
      </div>
    );
  }
  if (isLoading) return <Loading />;

  return (
    <section>
      <div className="container mx-auto my-10 px-5">
        <p className="text-xl text-blue-600 mt-10 mb-5">ফর্ম পূরন করুন</p>
        {/* <p>
          ভর্তির সময় আমরা আপনার সাথে যোগাযোগ করব, তখন টাকা পাঠিয়ে ভর্তি নিশ্চিত
          করবেন। পরবর্তীতে ভর্তি হতে না চাইলে, ভর্তি বাতিল করতে পারবেন এবং কোন
          টাকা দিতে হবে না।
        </p>
        <p>বুকিং দেওয়ার জন্য এই ফর্মটি পূরন করুন।</p> */}

        <div className="card flex-shrink-0 w-full shadow-2xl bg-base-100">
          <div className="card-body">
            <form className="form" onSubmit={handleFormSubmit}>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                <div>
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text ">আপনার নাম</span>
                    </label>
                    <input
                      type="text"
                      placeholder="আপনার নাম লিখুন"
                      className="input input-bordered w-full max-w-xs"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">আপনার বাবার নাম</span>
                    </label>
                    <input
                      type="text"
                      placeholder="আপনার বাবার নাম লিখুন"
                      className="input input-bordered w-full max-w-xs"
                      name="fathersName"
                      value={formData.fathersName}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">
                        আপনার টেলিগ্রাম নাম্বার লিখুন
                      </span>
                    </label>
                    <input
                      type="text"
                      placeholder="+88017 XXX XXX XXX"
                      className="input input-bordered w-full max-w-xs"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">
                        আপনি কোন দেশে থাকেন?{" "}
                        <span className="text-orange-500">
                          (বাধ্যতামূলক নয়)
                        </span>
                      </span>
                    </label>
                    <input
                      type="text"
                      placeholder="জার্মানি"
                      className="input input-bordered w-full max-w-xs"
                      name="address"
                      value={formData.address}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">
                        আপনার পেশা কি?{" "}
                        <span className="text-orange-500">
                          (বাধ্যতামূলক নয়)
                        </span>
                      </span>
                    </label>
                    <input
                      type="text"
                      placeholder="শিক্ষকতা"
                      className="input input-bordered w-full max-w-xs"
                      name="occupation"
                      value={formData.occupation}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div>
                  <div className="form-control">
                    <label className="label">
                      <span className="label-text">কোন গ্রুপে জয়েন করবেন?</span>
                    </label>
                    <label
                      htmlFor="field-male"
                      className="flex align-center mb-1"
                    >
                      <input
                        type="radio"
                        name="gender"
                        value="male"
                        id="field-male"
                        className="radio mr-3 checked:bg-sky-500"
                        onChange={handleChange}
                      />
                      <span>পুরুষ</span>
                    </label>
                    <label
                      htmlFor="field-female"
                      className="flex align-center mb-1"
                    >
                      <input
                        type="radio"
                        name="gender"
                        value="female"
                        id="field-female"
                        className="radio mr-3 checked:bg-pink-500"
                        onChange={handleChange}
                      />
                      <span>মহিলা</span>
                    </label>
                  </div>

                  <div className="form-control mt-5">
                    <label className="label">
                      <span className="label-text">
                        আপনি কি টাকা পাঠিয়েছেন?
                      </span>
                    </label>
                    <label
                      htmlFor="field-paid"
                      className="flex align-center mb-1"
                    >
                      <input
                        type="radio"
                        name="payment"
                        value="paid"
                        id="field-paid"
                        className="radio mr-3 checked:bg-green-500"
                        onChange={handleChange}
                      />
                      <span>টাকা পাঠিয়েছি</span>
                    </label>
                    <label
                      htmlFor="field-due"
                      className="flex align-center mb-1"
                    >
                      <input
                        type="radio"
                        name="payment"
                        value="due"
                        id="field-due"
                        className="radio mr-3 checked:bg-orange-500"
                        onChange={handleChange}
                      />
                      <span>টাকা পাঠাইনি</span>
                    </label>
                    {/* <label
                      htmlFor="field-scholarship"
                      className="flex align-center mb-1"
                    >
                      <input
                        type="radio"
                        name="payment"
                        value="scholarship"
                        id="field-scholarship"
                        className="radio mr-3 checked:bg-cyan-500"
                        onChange={handleChange}
                      />
                      <span>স্কলারশিপ পেতে চাই</span>
                    </label> */}
                  </div>

                  {formData.payment === "paid" && (
                    <>
                      <div className="form-control">
                        <label className="label">
                          <span className="label-text">
                            কতো টাকা পাঠিয়েছেন?
                          </span>
                        </label>
                        <input
                          type="text"
                          placeholder="এমাউন্ট লিখুন"
                          className="input input-bordered w-full max-w-xs"
                          name="amount"
                          value={formData.amount}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="form-control">
                        <label className="label">
                          <span className="label-text">
                            কোন নাম্বার থেকে টাকা পাঠিয়েছেন? শেষ 4 ডিজিট লিখুন
                          </span>
                        </label>
                        <input
                          type="text"
                          placeholder="আপনি যে নাম্বার থেকে টাকা পাঠিয়েছেন"
                          className="input input-bordered w-full max-w-xs"
                          name="senderNo"
                          value={formData.senderNo}
                          onChange={handleChange}
                        />
                      </div>
                    </>
                  )}
                  {formData.payment === "due" && (
                    <>
                      <div className="form-control">
                        <label className="label">
                          <span className="label-text">
                            কোন মন্তব্য থাকলে লিখুন
                          </span>
                        </label>
                        <textarea
                          type="text"
                          placeholder="যেমনঃ কোর্স ফী কবে দিতে চান, তারিখ উল্লেখ করুন"
                          className="textarea textarea-bordered h-32 w-full max-w-xs"
                          name="message"
                          value={formData.message}
                          onChange={handleChange}
                        />
                      </div>
                    </>
                  )}
                  {formData.payment === "scholarship" && (
                    <>
                      <div className="form-control">
                        <label className="label">
                          <span className="label-text">
                            <p>
                              ⚠ মানুষের দান-সদকার টাকা দিয়ে গরীব-অসহায়
                              ভাই-বোনদের স্কলারশিপের ব্যবস্থা করা হয় (বিনামূল্যে
                              পড়ার সুযোগ দেওয়া হয়)। আপনি কি মনে করেন যে আপনি
                              দান-সদকার টাকা নেওয়ার যোগ্য?
                            </p>
                            <p className="mt-2">
                              কেন আপনাকে স্কলারশিপ দেওয়া উচিত?
                            </p>
                          </span>
                        </label>
                        <textarea
                          type="text"
                          placeholder="একটি মেসেজ লিখুন, ব্যাখ্যা করুন কেন আপনাকে স্কলারশিপ দেওয়া প্রয়োজন। এই মেসেজটি আমাদের সম্মানিত কমিটির কাছে পাঠানো হবে।"
                          className="textarea textarea-bordered h-32 w-full max-w-xs"
                          name="message"
                          value={formData.message}
                          onChange={handleChange}
                        />
                      </div>
                    </>
                  )}

                  <div className="form-control mt-10">
                    <button
                      type="submit"
                      className="btn btn-primary w-full max-w-xs"
                    >
                      জমা দিন
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
