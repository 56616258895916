import React from "react";
import getUniqueKeywords from "./getUniqueKeywords";

export default function TelegramMenu({ chat, setSelectedId }) {
  const users = getUniqueKeywords(chat);

  const handleReceiveMessage = (user) => {
    setSelectedId(user?.message?.from?.id);
  };

  return (
    <div>
      <ul className="bg-base-200 text-base-content w-80 p-4">
        {/* Sidebar content here */}
        {users.map((user, index) => (
          <li key={index}>
            <span
              className="flex justify-between items-center cursor-pointer my-1 hover:bg-slate-300 p-1 rounded"
              onClick={() => handleReceiveMessage(user)}
            >
              <span>
                {index + 1} {" : "}
                {user?.message?.from?.first_name}
                <span className="opacity-0">{" : "}</span>
              </span>

              <span
                className={`text-xs badge ${
                  user?.message?.chat?.type === "private"
                    ? "badge-success"
                    : "badge-warning"
                }`}
              >
                {user?.message?.from?.id}
              </span>
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
}
