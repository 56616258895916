const data = [
  {
    course: "aqeedah",
    startDate: "1 Dec 2024",
    name: "সহীহ আক্বীদাহ ",
    duration: "৭.৫ মাস",
    teacher: "শায়খ আব্দুল্লাহ আল বাকি (হাফি.)",
    theme: ["from-blue-400", "to-sky-300"],
  },

  {
    course: "tajweed",
    name: "সহজ তাজউইদ",
    duration: "১ বছর ৩ মাস",
    teacher: "উস্তাদা শামসুন্নাহার",
    badge: "শুধুমাত্র মহিলাদের জন্য",
    badge_cls: "bg-pink-300",
    startDate: "15 Mar 2025",
    theme: ["from-red-400", "to-red-300"],
  },
  {
    course: "seerat",
    name: "সীরাতুন নবী (সাঃ)",
    duration: "৩ মাস",
    teacher: "শায়খ আব্দুল্লাহ আল কাফী (রহি.)",
    startDate: "20 Nov 2024",
    theme: ["from-fuchsia-400", "to-sky-200"],
  },
  {
    course: "fiqh",
    name: "ইসলামী ফিকহ",
    duration: "৬ মাস",
    teacher: "শায়খ আব্দুল্লাহ আল কাফী (রহি.)",
    startDate: "20 Mar 2025",
    theme: ["from-teal-500", "to-cyan-200"],
  },
  {
    course: "arabic",
    startDate: "5 Feb 2025",
    name: "আরবি ভাষা",
    duration: "১ বছর ৬ মাস",
    teacher: "ফাইসাল আব্দুল্লাহ",
    theme: ["from-green-400", "to-green-300"],
  },
  {
    course: "dua",
    name: "দোআ ও যিকির",
    duration: "২৫ দিন",
    teacher: "ফাইসাল আব্দুল্লাহ",
    badge: "রমাদান স্পেশাল কোর্স",
    badge_cls: "badge-warning",
    startDate: "15 Feb 2025",
    theme: ["from-red-300", "to-yellow-300"],
  },
  {
    course: "ramadan-siyam",
    name: "রমাদান ও সিয়াম",
    duration: "১ মাস",
    teacher: "শায়খ আব্দুল্লাহ আল বাকি (হাফি.)",
    badge: "রমাদান স্পেশাল কোর্স",
    badge_cls: "badge-warning",
    startDate: "15 Jan 2025",
    theme: ["from-orange-300", "to-red-300"],
  },
  {
    course: "tafseer",
    name: "সংক্ষিপ্ত তাফসীর",
    duration: "৩ মাস",
    teacher: "শায়খ আব্দুল্লাহ আল কাফী (রহি.)",
    startDate: "5 Apr 2025",
    theme: ["from-slate-400", "to-slate-300"],
  },
  // {
  //   course: "alquran",
  //   name: "আল কুরআন",
  //   duration: "১ বছর",
  //   teacher: "উস্তাদা হাবিবা সাদিয়া",
  //   badge: "শুধুমাত্র মহিলাদের জন্য",
  //   badge_cls: "bg-pink-300",
  //   startDate: "",
  //   theme: ["from-pink-400", "to-red-300"],
  // },
  {
    course: "asmaul-husna",
    name: "আসমাউল হুসনা",
    duration: "১ মাস",
    // badge: "শুধুমাত্র মহিলাদের জন্য",
    // badge_cls: "bg-pink-300",
    teacher: "ফাইসাল আব্দুল্লাহ",
    startDate: "",
    theme: ["from-green-400", "to-sky-400"],
  },
];

function getCourseData() {
  return data;
}

export { getCourseData };
