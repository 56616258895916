export default function getUniqueKeywords(arr) {
  const uniqueArr = arr.filter(
    (item, index, self) =>
      index ===
      self.findIndex((t) => t?.message?.from?.id === item?.message?.from?.id)
  );

  // const keywords = arr.map((item) => {
  //   const fromId =
  //     item?.message?.from?.id || item?.edited_message?.from?.id || 1704862497;
  //   return fromId;
  // });

  // const unique = [...new Set(keywords)]
  // const unique_users = arr.filter()

  return uniqueArr; // Convert to a Set to ensure uniqueness, then back to an array
}
