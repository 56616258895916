import {
  faCircleCheck,
  faRecordVinyl,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import React from "react";
import fiqh_class_img from "../../../assets/fiqh/law.png";
import bd_flag from "../../../assets/icons/bd_flag.ico";
import prize from "../../../assets/icons/gift_box.png";
import partyFlag from "../../../assets/partyFlag.png";
import Fiqh1Schedule from "./Fiqh1Schedule";
import Fiqh2Schedule from "./Fiqh2Schedule";
import FiqhCourseFee from "./FiqhCourseFee";
import CardFiqhNextBatch from "./Registration/CardFiqhNextBatch";

const fiqh_1_syllabus = [
  "ত্বহারাত/পবিত্রতা",
  "অযু, গোসল ও তায়াম্মুম",
  "হায়েজ, নিফাস ও ইস্তেহাযা",
  "আযান ও ইকামাত",
  "সলাতের পদ্ধতি",
  "সলাতের রুকন, ওয়াজিব ও সুন্নাহ",
  "বিতর ও তারাবীহ",
  "জুমুআর সলাত",
  "ঈদের সলাত",
  "ইস্তেস্কার সলাত",
  "চন্দ্র ও সূর্যগ্রহনের সলাত",
  "জানাযার সলাত",
  "যাকাতের বিধান",
  "কোন কোন সম্পদের উপর যাকাত ফরজ",
  "যাকাত বন্টনের খাত",
  "নফল সাদাকা",
];
const fiqh_2_syllabus = [
  "বিয়ের বিধি-বিধান",
  "বিয়ের রুকন ও শর্ত",
  "মুহাররামাত",
  "কাফেরদের সাথে বিয়ের বিধান",
  "বিয়ের মোহরানা",
  "বিয়ের অলিমা",
  "স্বামী-স্ত্রীর অধিকার",
  "গর্ভধারন ও প্রসবের বিধান",
  "স্ত্রীর অবাধ্যতা ও তার সমাধান",
  "তালাকের বিধান",
  "তালাকপ্রাপ্তা স্ত্রী ফেরত নেয়া",
  "খোলা তালাক",
  "ইদ্দত পালন",
  "হজ্জ-এর অর্থ",
  "হজ্জের ইতিহাস",
  "হজ্জের হুকুম ",
  "কার উপর হজ্জ ফরয ",
  "জীবনে কয়বার হজ্জ ফরয",
  "উত্তরাধিকার/মিরাসের বিধান",
  "মিরাসের নির্ধারিত অংশ প্রাপ্তগন",
  "মিরাসের নীতিমালা",
  "পরিত্যক্ত সম্পত্তি বন্টন",
  "আত্মীয়-স্বজনদের মিরাস",
  "অমুসলিমদের মিরাস, ইত্যাদি",
];
const FiqhInfo = () => {
  return (
    <div className="container mx-auto px-5 my-10">
      {/* Welcome  */}

      <section>
        <div className="rounded bg-gradient-to-tl from-sky-200 flex justify-between header-card">
          <div className="p-5">
            <p className="text-3xl">ইসলামী ফিকহ কোর্সে</p>
            <p className="text-4xl">স্বাগতম!</p>
          </div>
          <div className="p-5 hidden md:inline">
            <div className="flex justify-between">
              <div className="text-right font-serif align-middle mr-10">
                <p className="text-4xl ">دورة الفقه الميسر</p>
                <p className="text-2xl">في ضوء الكتاب و السنة</p>
              </div>
              <div>
                <img src={partyFlag} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Course Descripttion  */}
      <section className="my-10">
        <p className="text-xl">কোর্স পরিচিতিঃ</p>
        <p>
          আমরা প্রতিদিন যে আমলগুলো করে থাকি সেই আমলে ত্রুটি আছে কিনা সেটা জানা
          দরকার এবং আমলগত ত্রুটি থাকলে বিশুদ্ধ করা প্রয়োজন ও সে সম্পর্কে
          জ্ঞানার্জন করা জরুরী। আর সেজন্য ফিক্বহ কোর্স অত্যন্ত গুরুত্বপূর্ণ। এই
          কোর্সের মাধ্যমে আপনারা নিজেরাই যাকাত সম্পর্কে সঠিক জ্ঞান অর্জন করতে
          পারবেন এবং অন্যদেরকেও এসব প্রশ্নের উত্তর দিয়ে যাকাতের মতো
          গুরুত্বপূর্ণ ইবাদত পালনে সহযোগিতা করতে পারবেন ইন শা আল্লাহ।
        </p>
      </section>

      {/* in short  */}
      <section className="mt-20 mb-10">
        <div className="flex flex-col md:flex-row justify-around items-center">
          <div className="mb-8 md:mb-0">
            <img className="rounded-lg" src={fiqh_class_img} />
          </div>
          <div>
            <p className="text-xl my-4">এক নজরে</p>

            <p>
              <FontAwesomeIcon
                className="text-green-600 pr-2"
                icon={faCircleCheck}
              />
              ২টি লেভেল
            </p>
            <p>
              <FontAwesomeIcon
                className="text-green-600 pr-2"
                icon={faCircleCheck}
              />
              প্রতিটি লেভেল ৩ মাসের কোর্স
            </p>
            <p>
              <FontAwesomeIcon
                className="text-green-600 pr-2"
                icon={faCircleCheck}
              />
              প্রতিটি লেভেল লেভেল শেষে সার্টিফিকেট
            </p>
            <p>
              <FontAwesomeIcon
                className="text-green-600 pr-2"
                icon={faUserGroup}
              />
              পুরুষ - মহিলা আলাদা গ্রুপ
            </p>
            <p>
              <FontAwesomeIcon
                className="text-green-600 pr-2"
                icon="fa-solid fa-clock"
              />
              সপ্তাহে ৩ দিন রেকর্ডেড ক্লাস ও ক্লাস টেস্ট
            </p>
            <p>
              <FontAwesomeIcon
                className="text-red-600 pr-2"
                icon={faRecordVinyl}
                fade
              />
              সপ্তাহে ১টি লাইভ ক্লাস
            </p>
          </div>
        </div>
      </section>

      {/* Gift */}
      <section className="my-20 bg-yellow-200 rounded p-5 gift-box">
        <div className="grid grid-cols-1 md:grid-cols-2 items-center ">
          <div className="order-2 mx-auto">
            <p className="text-xl my-4">সেরা ১০ জনকে পুরষ্কৃত করা হবে</p>
            <p>
              <span className="font-bold">লেভেল ১ এ ৫ জন</span> +{" "}
              <span className="font-bold">লেভেল ২ এ ৫ জন </span>
            </p>
            <p>প্রটি লেভেলে ৩টি ১০০ মার্কের পরীক্ষা হবে।</p>
            <p>
              পরীক্ষায় সর্বোচ্চ রেজাল্টকারীদের মধ্যে সেরা ৫ জনকে পুরষ্কৃত করা
              হবে।
            </p>
          </div>
          <div className="order-1 md:order-3">
            <img className="rounded-lg block mx-auto my-10 " src={prize} />
          </div>
        </div>
      </section>

      <section>
        <div className="mt-10">
          <p className="text-xl my-8">লেভেল ১ এর বিষয়বস্তুঃ</p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
            {fiqh_1_syllabus.map((item, i) => (
              <p key={i}>
                <FontAwesomeIcon
                  className="text-green-700 pr-2"
                  icon={faCircleCheck}
                />
                {item}
              </p>
            ))}
          </div>
        </div>

        <div className="mt-10">
          <p className="text-xl my-8">লেভেল ২ এর বিষয়বস্তুঃ</p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
            {fiqh_2_syllabus.map((item, i) => (
              <p key={i}>
                <FontAwesomeIcon
                  className="text-blue-700 pr-2"
                  icon={faCircleCheck}
                />
                {item}
              </p>
            ))}
          </div>
        </div>
      </section>

      {/* schedule  */}
      <section className="my-24">
        <div className="flex items-center">
          <span className="text-xl">ক্লাসের সময়ঃ </span>
          <div className="ml-3">
            <img className="" src={bd_flag} alt="" />
          </div>
          <span className="ml-3">বাংলাদেশের সময় অনুযায়ী</span>
        </div>
        <p>
          লেভেল ১ এর শেষ হলে, একই সময়ে লেভেল ২ শুরু হবে। তাই পরবর্তী ব্যাচগুলোতে
          ক্লাস টাইম ভিন্ন হবে।
        </p>
        <p>
          💠 রেকর্ডেড ক্লাসগুলো শায়খ আব্দুল্লাহ আল কাফী (রহি.) এর টেক্সট, অডিও
          দিয়ে নেওয়া হবে।
        </p>
        <p>💠 সাপ্তাহিক লাইভ ক্লাসগুলো নিবেন, সম্মানিত উস্তাদ শামীম রেজা।</p>
        <Fiqh1Schedule />
        <Fiqh2Schedule />
      </section>

      {/* Course Fee  */}
      <FiqhCourseFee />

      {/* next batch  */}
      <section>
        <CardFiqhNextBatch />
      </section>
    </div>
  );
};

export default FiqhInfo;
