import React, { useEffect, useState } from "react";
import { IoMdRefresh } from "react-icons/io";
import ReceiveMessages from "./ReceiveMessages";
import SendMessage from "./SendMessage";
import TelegramMenu from "./TelegramMenu";

export default function TelegramBotMessage() {
  const [chat, setChat] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [selectedId, setSelectedId] = useState(
    chat[0]?.message?.from?.id || 1704862497
  );

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        await fetch(
          `https://api.telegram.org/${process.env.REACT_APP_TELEGRAM_API}/getUpdates`
        )
          .then((response) => response.json())
          .then((data) => {
            setChat(data.result);
          });
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };
    fetchMessages();
  }, [refresh]);

  return (
    <section className="">
      <div className="drawer lg:drawer-open">
        <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
        <div className="drawer-content ">
          <div className="mx-5">
            <ReceiveMessages data={chat} id={selectedId} />
            <SendMessage id={selectedId} refresh={() => setRefresh(!refresh)} />
          </div>
          <label
            htmlFor="my-drawer-2"
            className="btn btn-primary drawer-button lg:hidden"
          >
            Open List
          </label>
        </div>

        <div className="overflow-scroll h-[calc(100vh-60px)]">
          <div className="mx-auto text-center my-2 mt-3 flex justify-around">
            <span>Total Chats: {chat?.length}</span>
            <span
              className="cursor-pointer p-1 bg-lime-400 rounded "
              onClick={() => setRefresh(!refresh)}
            >
              <span>
                <IoMdRefresh />
              </span>
            </span>
          </div>
          <TelegramMenu chat={chat} setSelectedId={setSelectedId} />
        </div>
      </div>
    </section>
  );
}
