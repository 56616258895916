import React, { useEffect, useRef, useState } from "react";

export default function SendMessage({ id, refresh }) {
  const [reply, setReply] = useState("");
  const [replies, setReplies] = useState([]);
  const scrollRef = useRef(null); // Ref for the scrollable container

  const url = `https://api.telegram.org/${
    process.env.REACT_APP_TELEGRAM_API
  }/sendMessage?chat_id=${id}&text=${encodeURIComponent(reply)}`;

  const handleChange = (e) => {
    setReply(e.target.value);
  };
  const handleSendMessage = () => {
    refresh();
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (data.ok) {
          console.log(`Message sent !`);
          setReply("");
        } else {
          console.error("Failed to send message:", data.description);
        }
      })
      .catch((error) => console.error("Error:", error));
    setReplies([...replies, reply]);
  };

  useEffect(() => {
    setReplies([]);
  }, [id]);

  useEffect(() => {
    // Scroll to the bottom when replies change
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: scrollRef.current.scrollHeight,
        behavior: "smooth", // Smooth scrolling effect
      });
    }
  }, [replies]);

  return (
    <section className="w-full rounded bg-orange-200 my-2 py-3 ">
      {/* Scrollable container */}
      <div
        className="max-h-28 overflow-y-scroll"
        ref={scrollRef} // Attach the ref to the container
      >
        {replies.map((rep, index) => (
          <div
            key={index}
            className="bg-orange-300  rounded px-5 py-2 my-2 mx-5"
          >
            <p className="text-right ">{rep}</p>
          </div>
        ))}
      </div>
      <div className="w-full h-10 rounded my-5 px-5">
        <div className="flex gap-2 pb-3">
          <textarea
            type="text"
            placeholder="Write a reply"
            onChange={handleChange}
            value={reply}
            className="border border-gray-300 rounded-lg px-4 py-2 w-full focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-transparent"
          />
          <button
            onClick={handleSendMessage}
            className="h-full ml-auto bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg"
          >
            Send
          </button>
        </div>
      </div>
    </section>
  );
}
