import React from "react";
import { FaLink } from "react-icons/fa";

const group_link = (level, gender) => {
  const links = {
    1: {
      male: "https://t.me/+uCo043y_J0djNTU0",
      female: "https://t.me/+OfnaFIYQinJjZDRk",
    },
  };

  return links?.[level]?.[gender] || "Link not found";
};

export default function SeeratRegGroup({ gender }) {
  return (
    <a href={group_link(1, gender)} target="_blank" rel="noopener noreferrer">
      <div className="relative overflow-hidden md:order-2 w-56 h-28 rounded py-2 px-5 my-3 md:my-0 bg-gradient-to-tl from-cyan-200 to-cyan-100 payment-card">
        <div className="flex justify-between">
          <p className="text-cyan-600">{gender.toUpperCase()}</p>
          <FaLink className={`text-2xl text-cyan-600 ml-auto animate-pulse`} />
        </div>

        <FaLink className="text-8xl absolute -ml-6 mt-1 opacity-10 text-inherit " />

        <p className={`text-center font-bold text-xl my-2 text-cyan-600 `}>
          <span className="uppercase font-bold text-2xl">গ্রুপ লিংক</span>
        </p>
      </div>
    </a>
  );
}
